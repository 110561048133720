.profile-pic {
  margin-top: 50px;
  height: 600px;
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(4, 275px);
  grid-gap: 75px;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.MuiMobileStepper-dotActive {
  background-color: #F6A999 !important;
}

.itemTextContainer {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemTitle {
  font-family: Gopher, serif;
  font-weight: 600;
  font-size: 30px;
}

.itemDescription {
  font-family: Helvetica, serif;
  font-weight: 200;
  font-size: 15px;
}

.modalBody {
  display: flex;
}

.home-page {
  color: rgb(0, 0, 0);
  /* height: 100vh; */
  font-family: Helvetica, serif;
  /* max-height: 800px; */
}

.content {
  justify-items: center;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.about-me-section {
  justify-self: start;
  margin-top: 175px;
}

.about-me-header {
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Gopher, serif;
}

.about-me-inner {
  width: 65%;
  padding-left: 75px;
  margin-top: 10px;
}

.about-me-text {
  font-size: 20px;
  color: #000000;
  font-style: normal;
}

.button-container {
  margin-top: 30px;
  text-align: center;
  width: 65%;
  padding-left: 75px;
}

.button {
  color: rgb(255, 251, 246);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 15px;
  width: 180px;
  border-style: solid;
  font-size: 20px;
  font-family: Helvetica, sans-serif, sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.submit-button {
  color: rgb(255, 251, 246);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 15px;
  width: 180px;
  border-style: solid;
  font-size: 20px;
  font-family: Helvetica, sans-serif, sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  background-color: grey;
}

.socials {
  justify-content: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
}


.header {
  font-size: 125px;
  text-align: center;
  font-weight: 600;
  font-family: Gopher, serif;
  margin-top: 25px;
}

.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {

  .itemTextContainer {
    margin-bottom: 20px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .profile-pic {
    margin-top: 0px;
    height: 510px
  }

  .portfolio-container {
    grid-template-columns: 275px;
    grid-gap: 40px;

  }

  .portfolioItem {}

  .modalBody {
    flex-direction: column;
    gap: 20px;
  }

  .home-page {}

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .about-me-section {
    margin-top: 10px;
  }

  .about-me-header {
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: Gopher, serif;
    text-align: center;
    margin-bottom: 3px;
  }

  .about-me-inner {
    padding-left: 0px;
    width: initial;
    margin-top: 0;
  }

  .about-me-text {
    padding-right: 30px;
    padding-left: 35px;
    text-align: left;
  }

  .button-container {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    width: initial;
    padding-left: 0;
  }

  .button {}

  .header {
    font-size: 32px;
  }

  .fade-in-image {}
}